.title_block {
    margin-bottom: 32px;
}

.date {
    margin-top: 6px;
    display: block;
    opacity: 0.6;
}

.subtitle {
    margin-bottom: 12px;
    display: block;
}

.list_link {
    margin-bottom: 12px;
    display: block;
    color: #FF5D7B !important;
}