.renderer p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-light-text-primary)
}

.renderer h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-light-text-primary);
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
}

.renderer h3 span {
    font-weight: inherit !important;
}

.renderer ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.renderer ol, ul li {
    margin-bottom: 12px;
}

.renderer ol, ul li span {
    font-size: 14px;
    line-height: 24px;
}

.renderer a {
    color: var(--color-light-text-accent);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit
}