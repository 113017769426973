@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

:root {
  --color-light-bg-accent: #FF5D7B;
  --color-light-border-accent: #FF5D7B;
  --color-light-text-accent: #FF5D7B;
  --color-light-graphic-accent: #FF5D7B;
  --link-default-color: #FF5D7B;
}

body, html {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat';
 
} 
