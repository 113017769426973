.header {
  background-color: var(--color-light-bg-primary);
  padding: 0px 40px;
  display: flex;
  height: 112px;
  box-shadow: 0px 4px 20px 0px #00000000;
  position: fixed;
  -webkit-position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 6;
  transition: height 0.2s ease-in-out, box-shadow 0.5s ease-in-out;

  @media (max-width: 1023px) {
    padding: 0px 48px;
  }

  @media (max-width: 767px) {
    padding: 0px 24px;
  }

}

.headerTransformed {
  height: 80px;
  box-shadow: 0px 4px 20px 0px #00000014;
}

.inner_content {
  max-width: 1060px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

}

.logo {
  min-width: 172px;
}